/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_TRIPS.TITLE,
    absolute_slug: PAGES.ABOUT_TRIPS.PATH,
  },
];

const AboutTripsPage = () => (
  <Layout page="about/our-trips">

    <Helmet
      title="Haka Tours New Zealand | Our Trips"
      meta={[
        {
          name: 'description', content: 'Our New Zealand trip costs vary depending on the tour. Take a look at our tours and find the best option to suit you! We offer tours to match everyone’s needs!',
        },
        { name: 'og:title', content: 'Haka Tours New Zealand | Our Trips' },
        {
          name: 'og:description', content: 'Our New Zealand trip costs vary depending on the tour. Take a look at our tours and find the best option to suit you! We offer tours to match everyone’s needs!',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-trips/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-trips-banner.jpg?fit=crop&auto=format">
      <h1 className="c-heading c-heading--h0">New Zealand Trips</h1>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h2 className="c-heading c-heading--h2">Welcome to Haka Tours!</h2>
        <p>
          Offering unforgettable landscapes, heaps of adventure activities and so much more, New Zealand really does have it all.
          Whatever adventure looks like to you, we have a tour to match. Our tours include everything you need for a stress-free trip:
          accommodation, transport, Kiwi tour guide, breakfast, and a few <a href="https://hakatours.com/included_activities/" target="_blank" rel="noreferrer">key inclusions</a> which are all included in your New Zealand trip cost.
          It’s simply not to be missed! That means you’re only paying for stuff that is 100% essential to your experience.
          Plus we’re passionate about caring for Aotearoa New Zealand so we aim for our guests to leave nothing but a literal footprint.
          We have recently joined forces with <a href="https://www.intrepidtravel.com/">Intrepid Travel</a>, so check them out, if you’re looking for a voyage beyond New Zealand.
        </p>
      </div>
    </section>
    <AltPanels items={[
      {
        title: 'Our Small Group Size',
        content: `<p>Our core belief has always been that “size matters” and that travelling in a small group makes for a far better travel experience.
        With an average group size of just 12 (maximum 16), your guide can customise a day's activities to the needs of the group; plus,
        you won’t have to wait around for a big bus load of people, ensuring you make the most of your time on your New Zealand trip.
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/000/our_trips_small_groups.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Customisable Tours',
        content: `<p>No two passengers are the same, so we give you the ability to tailor your tour to be as individual as you are, with a range of add-on activities, accommodation upgrades and extensions.
        We highly recommend booking your must-do activities in advance especially in the busy summer season, but whether you add on any activities or not, there’s always plenty to see and do on a Haka Tour.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/000/our_trips_customisable_tours.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Travelling Solo?',
        content: `<p>It’s been said that a journey is best measured in friends, not in miles, and with 70% of our guests travelling on their own, a Haka Tour soon feels like a bunch of mates experiencing the road trip of a lifetime.
        Everyone has one thing in common – a desire to make the most of every moment and the friendships you’ll form on tour will make the whole experience that much richer.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/000/our-trips-travelling-solo.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Accommodation On Tour',
        content: `<p>All Haka tours include accommodation in your New Zealand trip cost – we organise for you and your tour companions to stay in accommodation tailored to your tour's needs.
        We have two tiers of tours and each have upgrade options available. Accommodation on our New Zealand adventure and snow tours consists of dorm share accommodation at quality lodges,
        hostels and motels (including our own <a href="https://www.hakalodge.com/?utm_source=HakaTours&utm_medium=Website&utm_campaign=OurTrips" target="_blank">Haka Lodges</a>).
        Guests on our Haka Plus Tours are looking for a bit more comfort, so their accommodation is in twin-share rooms at 3 and 4 star hotels.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/000/our-trips-accommodation-on-tour.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Culture on Tour',
        content: `<p>Showcasing our indigenous Māori culture is incredibly important to us, which is why we weave equal measures of culture and adventure into all our small group tours. 
        When you join a Haka Tour, you’ll be introduced to Māori culture from the outset with a <a href="/about-us/our-haka-welcome-haka-and-farewell/" target="_blank">traditional Māori welcome or Mihi</a>, followed by a Poroporoaki (farewell) at the end of the tour.</p>
        <p>We also include a cultural experience or two on every Haka Tour – so whether you’re carving a greenstone pendant, paddling a waka (Māori canoe), or eating a hāngī, you’ll return home with a deeper cultural connection which aims to enhance your New Zealand experience.</p>
        <p>Discover more on our following blogs: <a href="https://hakatours.com/blog/culture/" target="_blank">why culture is important to us</a> and <a href="https://hakatours.com/blog/cultural-inclusions-on-tour/" target="_blank">cultural inclusions on tour</a>.</p>
        `,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/000/our-purpose-culture.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      }
    ]}
    />

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <p>&nbsp;</p>
      </div>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutTripsPage;
